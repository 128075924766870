import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import Team from "../components/Common/Team";
import FunFactsArea from "../components/Common/FunFactsArea";
import Partner from "../components/Common/Partner";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
import AboutOne from "../assets/images/about-one.png";
import FeedbackStyleFour from "../components/Common/FeedbackStyleFour";

const CalismaSekilleri = () => (
    <Layout>
        <SEO title="Konya Yazılım Firmaları Yeni Haberler" /> 

        <Navbar />
        <PageBanner pageTitle="Pixel Yazılım" />

        <FeedbackStyleFour />
   
        
        <Footer />
    </Layout>
)

export default CalismaSekilleri;
